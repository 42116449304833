const point = "https://umpb.rekopgames.com"
const mediapoint = "https://cb.rekopgames.com"

/* SOCKET TOKEN */
const DEALER_MIDDLEWARE_TOKEN = '830a5200-8709-44a5-9bc2-8be743da059e'

/* MONITOR TOKEN */
const DEALER_MONITOR_TOKEN = 'be2510f7-35b5-4a6c-ab09-73eacb9f0b11'

/* POINTS */
const startpoint = `${point}/api/`


export { point, mediapoint, startpoint, DEALER_MIDDLEWARE_TOKEN, DEALER_MONITOR_TOKEN }
