import React from 'react'
import ReactDOM from 'react-dom/client'

/* Constants */
import { env } from '../src/constants'

import App from './App'
import reportWebVitals from './reportWebVitals'

/* CSS */
import './design/css/app.css'
import './design/css/pincode.css'
import './design/css/game.css'
import './design/css/logo.css'
import './design/css/dealer.css'
import './design/css/timing.css'
import './design/css/pause.css'
import './design/css/messages.css'

/* Fields */
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(token === env.DEALER_MONITOR_TOKEN ? <App /> : <div className="page-404"><span>404</span> Page not found</div>)

reportWebVitals()